<template>
    <div class="feature-wrapper">
        <Upvotes 
            :feature="feature" 
            @onUpvote="$emit('onUpvote', $event)"
        />
        <div>
            <div class="feature-title">{{ feature.title }}</div>
            <div class="feature-status">
                <div class="status" :class="parseStatusClass(feature.status)"/>
                <span>{{ parseStatus(feature.status) }}</span>
                <div class="feature-date">{{ parseDate(feature.updated_at) }}</div>
            </div>
            <p class="feature-details">{{ feature.details }}</p>
            <div v-if="['under-review', 'declined'].includes(feature.status)" class="visibility">
                Visível apenas para você{{ feature.status === 'under-review' ? ' até que seja revisado' : '.' }} 
            </div>
            <div class="person-name">
                <Avatar size="20" :src="feature.person?.picture" />
                {{ feature.person?.name }}
            </div>
        </div>
    </div>
</template>
<script>
import { parseStatusClass, parseDate, parseStatus } from '../../utils/helper'

export default {
    components: {
        Upvotes: () => import('@/modules/featureRequests/components/Upvotes'),
        Avatar: () => import('@/components/General/Avatar'), 
    },
    props: {
        feature: {
            type: Object,
            required: true,
        },
    },
    methods: {
        parseStatusClass, 
        parseDate, 
        parseStatus,
    }
}
</script>
<style lang="scss" scoped>
.feature-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 1rem;
    border-top: 1px solid var(--neutral-200);
}
.feature-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #424242;
}
.feature-details {
    font-size: 0.8rem;
    color: #424242;
}
.feature-status {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 10px;

    .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
        &.under-review {
            background-color: #cbcbcb;
        }

        &.approved {
            background-color: #4CAF50;
        }
        &.declined {
            background-color: #F44336;
        }

        &.in-progress {
            background-color: #2196F3;
        }
        &.completed {
            background-color: #0a28ef;
        }
    }
}
.feature-date {
    color: var(--type-placeholder);
    font-size: 0.8rem;
    margin-left: 15px;
    margin-bottom: -3px;
}
.person-name {
    display: flex;
    gap: 5px;
    color: var(--type-placeholder);
    font-size: 0.8rem;
    margin-top: 10px;
}
.visibility {
    font-size: 0.8rem;
    color: #c07f06;
}
</style>